import { BlankImg, EditIcon, ProfilePicture } from "assets/images";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import ApiContext from "context/ApiContext";
import AuthContext from "context/AuthContext";
import { Field, Form, Formik } from "formik";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useEffect, useState, useContext } from "react";
import { formatSocialHandle } from "utils/removeHandle";
import Host from "../host/Host";
import states from "components/Data/states.json";
import CustomModal from "components/Modal/CustomModal";
import PhotoHandler from "./PhotoHandler";
import AddEmail from "./AddEmail";
import {
  Tiktok,
  Instagram,
  Twitter,
  Facebook,
  PatchCheckFill,
} from "react-bootstrap-icons";
import { Spinner } from "react-bootstrap";
import PhoneVerifyPage from "./PhoneVerifyPage";
import PaymentContext from "context/PaymentContext";
import BankForm from "components/Stripes/BankForm";
import UpdateBankForm from "components/Stripes/UpdateBankForm";

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const HostUserProfile = () => {
  const { userProfile, user } = useContext(AuthContext);
  const { getCardDetails, cardDetails } = useContext(PaymentContext);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [showA, setShowA] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseA = () => setShowA(false);
  const handleShowA = () => setShowA(true);
  const { userPaymentDetail } = useContext(PaymentContext);
  const [cardAdded, setIsCardAdded] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const [showBankFormUpdate, setShowBankFormUpdate] = useState(false);
  const handleCloseB = () => setShowBankForm(false);
  const handleCloseC = () => setShowBankFormUpdate(false);
  const [sureShow, setSureShow] = useState(false);
  const handleCloseSure = () => setSureShow(false);

  const checkPaymentMethod = async () => {
    setLoading(true);
    try {
      const res = await userPaymentDetail();
      if (res?.data?.payment_method) {
        setIsCardAdded(true);
        setLoading(false);
      } else {
        setIsCardAdded(false);
        setLoading(false);
      }
    } catch (err) {
      setIsCardAdded(false);
      setLoading(false);
    }

    if (cardAdded) {
      await getCardDetails();
    }
  };

  useEffect(() => {
    checkPaymentMethod();
  }, [cardAdded]);

  const pic = userProfile?.profile_picture?.file
    ? userProfile.profile_picture.file
    : "";
  const profilepicURL = pic;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  if (loading) {
    return <PurebrandLoader />;
  } else {
    if (!userProfile?.complete_profile) {
      return <Host />;
    }
    return (
      <>
        <div className="user-profile-wrapper">
          <div className="container-wrap ">
            <div className="main-body">
              <div className="row gutters-sm">
                <div className="col-md-4 mb-3">
                  <div className="card cardc">
                    <div className="card-body">
                      <div className="d-flex flex-column align-items-center text-center">
                        <div className="image-circular-edit">
                          <div className="image-view">
                            <img
                              src={
                                isValidUrl(profilepicURL)
                                  ? profilepicURL
                                  : BlankImg
                              }
                              alt="Admin"
                              className="rounded-circle"
                              width="150"
                              onClick={handleShow}
                            />
                            <CustomModal
                              showStatus={show}
                              clickStatus={handleClose}
                              buttonMessage="Close"
                              closeStatus={handleClose}
                              title="Photo"
                              message={
                                <PhotoHandler
                                  status="watch"
                                  photo={
                                    userProfile?.profile_picture?.file
                                      ? userProfile?.profile_picture?.file
                                      : ProfilePicture
                                  }
                                  closing={handleClose}
                                />
                              }
                              secondMessage="none"
                              secondButton={false}
                            />
                          </div>
                          <div className="image-edit text-end">
                            <img
                              className="edit-logo"
                              src={EditIcon}
                              alt="edit"
                              onClick={handleShowA}
                            />
                            <CustomModal
                              showStatus={showA}
                              clickStatus={handleCloseA}
                              buttonMessage="Close"
                              closeStatus={handleCloseA}
                              title="Photo"
                              message="editing"
                              secondMessage="none"
                              secondButton={false}
                            />
                          </div>
                        </div>

                        <div className="mt-3">
                          <h4>
                            {user.firstName} {user.lastName.charAt(0)}.
                          </h4>
                          <p className="text-secondary mb-1">
                            {userProfile.user_geo_location.address}
                          </p>
                          <p className="text-muted font-size-sm">
                            {userProfile.user_geo_location.city},{" "}
                            {userProfile.user_geo_location.state},{" "}
                            {userProfile.user_geo_location.zip_code}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {userProfile.user_social ? (
                    <div className="card cardc mt-3">
                      <ul className="list-group list-group-flush">
                        {userProfile?.user_social &&
                        userProfile.user_social.tiktok_handle === "" ? (
                          <></>
                        ) : (
                          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                            <h6 className="mb-1">
                              <Tiktok size={25} /> Tiktok
                            </h6>
                            <span className="text-secondary">
                              {" "}
                              @{userProfile?.user_social?.tiktok_handle}
                            </span>
                          </li>
                        )}
                        {userProfile?.user_social &&
                        userProfile.user_social.twitter_handle === "" ? (
                          <></>
                        ) : (
                          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                            <h6 className="mb-1">
                              <Twitter size={25} /> Twitter
                            </h6>
                            <span className="text-secondary">
                              @{userProfile?.user_social?.twitter_handle}
                            </span>
                          </li>
                        )}
                        {userProfile?.user_social &&
                        userProfile.user_social.instagram_handle === "" ? (
                          <></>
                        ) : (
                          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                            <h6 className="mb-1">
                              <Instagram size={25} color={"#e35e6a"} />{" "}
                              Instagram
                            </h6>
                            <span className="text-secondary">
                              @{userProfile?.user_social?.instagram_handle}
                            </span>
                          </li>
                        )}

                        {userProfile.user_social &&
                        userProfile.user_social.facebook_handle === "" ? (
                          <></>
                        ) : (
                          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                            <h6 className="mb-1">
                              <Facebook size={25} color="#1877f2" /> Facebook
                            </h6>
                            <span className="text-secondary">
                              @{userProfile?.user_social?.facebook_handle}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  ) : null}

                  {user.role === 3 ? <AddEmail /> : <></>}
                </div>
                <div className="col-md-8">
                  <div className="card cardc">
                    <div className="card-body">
                      <h5>Personal information</h5>
                      <hr />
                      {isEditing === false ? (
                        <NormalView setIsEditing={setIsEditing} />
                      ) : (
                        <EditingView setIsEditing={setIsEditing} />
                      )}
                    </div>
                  </div>
                  {/* {user.role === 2 ? <StoreUserProfile /> : <></>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {user.role === 2 && (
          <div className="card cardc p-4">
            <h5>Payment Method Information</h5>
            {!cardAdded ? (
              <div className="  text-center  ">
                <h4 className="p-5">
                  To Get Started, Please Add Payment Method
                </h4>
                <div className=" text-center">
                  <button
                    className="btn btn-primary "
                    disabled={cardAdded}
                    onClick={() => {
                      setShowBankForm(true);
                    }}
                  >
                    + Add Payment Method
                  </button>
                  <CustomModal
                    showStatus={showBankForm}
                    clickStatus={handleCloseB}
                    buttonMessage="Close"
                    title="Add Card Details"
                    message={
                      <BankForm
                        sureShow={sureShow}
                        setSureShow={setSureShow}
                        showBankFormUpdate={showBankFormUpdate}
                        setShowBankFormUpdate={setShowBankFormUpdate}
                      />
                    }
                    secondButton={false}
                    secondMessage="none"
                  />
                </div>
              </div>
            ) : (
              <>
                {cardAdded && (
                  <div className="text-center p-4">
                    <h6 className="p-4 h4">Your Card</h6>
                    <div className="cardView text-center">
                      <div
                        className="addedCard card cardc py-4"
                        style={{
                          width: "500px",
                          margin: "0 auto",
                          background: "#eee",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        <span>{cardDetails?.card?.brand?.toUpperCase()}</span>
                        <span> **** **** **** {cardDetails?.card?.last4}</span>
                        <span>
                          Expires : {cardDetails?.card?.exp_month}/
                          {cardDetails?.card?.exp_year}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="text-center p-4">
                  <h6 className="p-4">
                    You have already added your Payment card. If you want to
                    update you card information, click on the button below.
                  </h6>
                  <button
                    className="btn btn-primary "
                    onClick={() => {
                      setShowBankFormUpdate(true);
                    }}
                  >
                    Update Payment Method
                  </button>
                  <CustomModal
                    showStatus={showBankFormUpdate}
                    clickStatus={handleCloseC}
                    buttonMessage="Close"
                    title="Are you sure? You want to update your payment method "
                    message={
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setSureShow(true);
                        }}
                      >
                        Yes Sure
                      </button>
                    }
                    closeStatus={handleCloseC}
                  />
                  <CustomModal
                    showStatus={sureShow}
                    clickStatus={handleCloseSure}
                    buttonMessage="Close"
                    title="Update Card Details"
                    message={
                      <UpdateBankForm
                        sureShow={sureShow}
                        setSureShow={setSureShow}
                        showBankFormUpdate={showBankFormUpdate}
                        setShowBankFormUpdate={setShowBankFormUpdate}
                      />
                    }
                    closeStatus={handleCloseSure}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  }
};

export default HostUserProfile;

const NormalView = (props) => {
  const { userProfile, user, updateProfile } = useContext(AuthContext);
  const { putUserOtp } = useContext(ApiContext);
  const { setIsEditing } = props;
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);

  const handleOtpPut = async (param) => {
    setLoading(true);
    try {
      const res = await putUserOtp(
        {
          number: userProfile.phone_number,
        },
        user.uid,
        param
      );
      if (res) {
        try {
          if (await updateProfile()) {
            setLoading(false);
          }
        } catch (error) {
          setLoading(true);
        }
      }
    } catch (error) {
      setLoading(true);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="grow" />
        <Spinner animation="grow" />
        <Spinner animation="grow" />
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-3">
          <h6 className="mb-1">Full Name</h6>
        </div>
        <div className="col-sm-9 text-secondary">
          {user.firstName} {user.lastName}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-3">
          <h6 className="mb-1">Email</h6>
        </div>
        <div className="col-sm-9 text-secondary">{user.email}</div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-3">
          <h6 className="mb-1">Phone</h6>
        </div>
        <div className="col-sm-7 text-secondary">
          {userProfile.number_is_verified === true ? (
            <span>
              <PatchCheckFill size={25} color="#2d9dd7" />{" "}
            </span>
          ) : null}{" "}
          {userProfile.phone_number}
        </div>
        <div className="col-sm-2 text-secondary">
          {userProfile.number_is_verified === true ? (
            <button
              className="small_verify_email_unlink"
              onClick={(e) => {
                handleOtpPut(`?verify=false`);
              }}
            >
              Unsubscribe
            </button>
          ) : (
            <>
              <button onClick={handleShow} className="small_verify_email">
                Subscribe
              </button>
              <CustomModal
                showStatus={show}
                clickStatus={handleClose}
                buttonMessage="Close"
                modalClass={"opt_validator_modal"}
                closeStatus={handleClose}
                title="Verify"
                message={
                  <PhoneVerifyPage
                    userId={user.uid}
                    phoneNumber={userProfile.phone_number}
                    handleClose={handleClose}
                    updateProfile={updateProfile}
                  />
                }
                secondMessage="noClose"
                secondButton={false}
              />
            </>
          )}
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col-sm-3">
          <h6 className="mb-1">Address</h6>
        </div>
        <div className="col-sm-9 text-secondary">
          {userProfile.user_geo_location.address},{" "}
          {userProfile.user_geo_location.city},{" "}
          {userProfile.user_geo_location.state},{" "}
          {userProfile.user_geo_location.zip_code}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-3">
          <h6>About me</h6>
        </div>
        <div className="col-sm-9 text-secondary">{userProfile.about_me}</div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="add_email_button"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </button>
        </div>
      </div>
    </>
  );
};

const EditingView = (props) => {
  const { user, userProfile, updateUserProfile, updateStoreUserProfile } =
    useContext(AuthContext);
  const { getGeoCoords } = useContext(ApiContext);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { setIsEditing } = props;

  const getAccounts = () => {
    const array = [];
    userProfile.user_shopify.map((store) =>
      array.push({
        store_email: store.store_email,
      })
    );
    return array;
  };

  const handleSubmit = (values) => {
    const accounts = getAccounts();
    setSubmitting(true);
    values.instagram_handle = formatSocialHandle(values.instagram_handle);
    values.twitter_handle = formatSocialHandle(values.twitter_handle);
    values.tiktok_handle = formatSocialHandle(values.tiktok_handle);
    values.facebook_handle = formatSocialHandle(values.facebook_handle);
    values.accounts = accounts;
    const response = getGeoCoords(values);
    response
      .then(function (response) {
        if (response.status === 200 && response.data.results.length > 0) {
          values.latitude = response.data.results[0].geometry.location.lat;
          values.longitude = response.data.results[0].geometry.location.lng;
          if (user.role === 3 || user.role === 1) {
            const res = updateUserProfile(values);
            res
              .then(function (response) {
                if (response.status === 200) {
                  setSubmitting(false);
                  setIsEditing(false);
                  setError(false);
                }
              })
              .catch(function (error) {
                setSubmitting(false);
                setError(true);
              });
          } else if (
            user.role === 2 ||
            user.role === 4 ||
            user.role === 5 ||
            user.role === 6
          ) {
            values.demoCommission = userProfile.store.demo_commission;
            values.commissionType = userProfile.store.demo_commission_type;
            values.salesCommission = userProfile.store.demo_sales_commission;
            values.salesCommissionType =
              userProfile.store.demo_sales_commission_type;
            values.discountType = userProfile.store.discount_type;
            values.discountGiven = userProfile.store.discount_given;
            values.payment_frequency = userProfile.store.payment_frequency;
            values.no_show_demo_commission =
              userProfile.store.no_show_demo_commission;
            values.no_show_demo_commission_type =
              userProfile.store.no_show_demo_commission_type;
            const res = updateStoreUserProfile(values);
            res
              .then(function (response) {
                if (response.status === 200) {
                  setSubmitting(false);
                  setIsEditing(false);
                  setError(false);
                }
              })
              .catch(function (error) {
                setSubmitting(false);
                setError(true);
              });
          }
        } else {
          setError(true);
          setSubmitting(false);
        }
      })
      .catch(function (error) {
        setSubmitting(false);
        setError(true);
      });
  };

  useEffectOnce(() => {});
  return (
    <>
      <Formik
        initialValues={{
          phone_number: userProfile.phone_number,
          about_me: userProfile.about_me,
          instagram_handle: userProfile.user_social
            ? userProfile?.user_social?.instagram_handle
            : "",
          twitter_handle: userProfile.user_social
            ? userProfile?.user_social?.twitter_handle
            : "",
          tiktok_handle: userProfile.user_social
            ? userProfile?.user_social?.tiktok_handle
            : "",
          facebook_handle: userProfile.user_social
            ? userProfile?.user_social?.facebook_handle
            : "",
          latitude: userProfile.user_geo_location.latitude,
          longitude: userProfile.user_geo_location.longitude,
          address: userProfile.user_geo_location.address,
          street_number: userProfile.user_geo_location.street_number,
          city: userProfile.user_geo_location.city,
          state: userProfile.user_geo_location.state,
          zip_code: userProfile.user_geo_location.zip_code,
          profile_picture_id: userProfile?.profile_picture?.id
            ? userProfile?.profile_picture?.id
            : "",
          store_email: userProfile?.user_shopify[0]?.store_email
            ? userProfile?.user_shopify[0]?.store_email
            : user?.email,
        }}
        onSubmit={handleSubmit}
      >
        {({ touched }) => (
          <Form>
            {user.role === 1 ? (
              <></>
            ) : (
              <>
                <div className="row align-items-center">
                  <div className="col-sm-3">
                    <h6 className="mb-1">Tiktok</h6>
                  </div>
                  <div className="col-sm-6 text-secondary">
                    <Field
                      type="text"
                      name="tiktok_handle"
                      placeholder="@example"
                      className={`form-control form-control-sm`}
                    />
                  </div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-sm-3">
                    <h6 className="mb-1">Instagram</h6>
                  </div>
                  <div className="col-sm-6 text-secondary">
                    <Field
                      type="text"
                      name="instagram_handle"
                      placeholder="@example"
                      className={`form-control form-control-sm`}
                    />
                  </div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-sm-3">
                    <h6 className="mb-1">Twitter</h6>
                  </div>
                  <div className="col-sm-6 text-secondary">
                    <Field
                      type="text"
                      name="twitter_handle"
                      placeholder="@example"
                      className={`form-control form-control-sm`}
                    />
                  </div>
                </div>
                <hr />
                <div className="row align-items-center">
                  <div className="col-sm-3">
                    <h6 className="mb-1">Facebook</h6>
                  </div>
                  <div className="col-sm-6 text-secondary">
                    <Field
                      type="text"
                      name="facebook_handle"
                      placeholder="@example"
                      className={`form-control form-control-sm`}
                    />
                  </div>
                </div>
                <hr />
              </>
            )}
            <hr />
            <div className="row align-items-center">
              <div className="col-sm-3">
                <h6 className="mb-1">About me</h6>
              </div>
              <div className="col-sm-6 text-secondary">
                <Field
                  as="textarea"
                  rows={2}
                  name="about_me"
                  placeholder="About me"
                  className={`form-control form-control-sm`}
                />
              </div>
            </div>
            <hr />
            <div className="row align-items-center">
              <div className="col-sm-3">
                <h6 className="mb-1">Address line 1</h6>
              </div>

              <div className="col-sm-6 text-secondary">
                <Field
                  type="text"
                  name="address"
                  placeholder="Address"
                  className={`form-control form-control-sm`}
                />
              </div>
            </div>
            <hr />
            <div className="row align-items-center">
              <div className="col-sm-3">
                <h6 className="mb-1">City</h6>
              </div>
              <div className="col-sm-6 text-secondary">
                <Field
                  type="text"
                  name="city"
                  placeholder="City"
                  className={`form-control form-control-sm`}
                />
              </div>
            </div>
            <hr />
            <div className="row align-items-center">
              <div className="col-sm-3">
                <h6 className="mb-1">State</h6>
              </div>
              <div className="col-sm-6 text-secondary">
                <Field as="select" name="state" className={`form-select`}>
                  {states.map((state, index) => (
                    <option key={index} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
            <hr />
            <div className="row align-items-center">
              <div className="col-sm-3">
                <h6 className="mb-1">Zip Code</h6>
              </div>
              <div className="col-sm-6 text-secondary">
                <Field
                  type="text"
                  name="zip_code"
                  placeholder="Zip Code"
                  className={`form-control form-control-sm`}
                />
              </div>
            </div>

            {error ? (
              <>
                <hr />
                <div>
                  <p>Some error occured, please check your address</p>
                </div>
              </>
            ) : (
              <></>
            )}
            <hr />
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-9 text-secondary">
                <button
                  className="add_email_button"
                  type="submit"
                  disabled={submitting}
                >
                  Save Changes
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  className="add_email_button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
